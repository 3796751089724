let $screensaver;
let $timeout;

function cacheDOM() {
    $screensaver = document.querySelector('.js-screensaver');
}

function bindScreensaver($saver) {
    const $video = $saver.querySelector('.js-screensaver__video');
    window.addEventListener("mousemove", () => {
        clearTimeout($timeout);
        $saver.classList.remove('active');
        $video.pause();
        $timeout = setTimeout(function () {
            if (document.body.classList.contains('modal-open')) return;

            $saver.classList.add('active');
            $video.play();
        }, 60000)
    })
}

export default function init() {
    cacheDOM();

    if ($screensaver) {
        bindScreensaver($screensaver)
    }
}