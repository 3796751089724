import MicroModal from "micromodal";
import Glide from '@glidejs/glide'



MicroModal.init({
    onShow: modal => {
        const slideshow = modal.querySelector('.glide');

        if (slideshow) {
            new Glide(slideshow).mount()
        }
    },
    onClose: () => {
        document.body.classList.remove('modal-open')
    }
});